import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from '../screen/Login/Login';
import Ads from '../screen/Ads/Ads';
import Refer from '../screen/Refer/Refer';
import Subscription from '../screen/Subscription/Subscription';


const Navigation = () => {
    return (
        <BrowserRouter basename='admin'>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<Ads />} />
                <Route path="/refer" element={<Refer />} />
                <Route path="/subscription" element={<Subscription />} />
            </Routes>

        </BrowserRouter>)
}

export default Navigation
