import React, { useRef } from 'react'
import "./modal.css"
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { toast } from 'react-hot-toast'
import config from '../../config'
import { PostAds, editAds, uploadImage } from '../../service/APIRequest'

function CreatAds({ type, isShow, setisShow, SingleRow }) {
    console.log('type', type)
    const FileInputRef = useRef(null)
    const typeOptions = [
        'Straight Ads',
        'LGBTQ (Male-focused) Ads',
        'LGBTQ (Female-focused) Ads',
    ];
    const [FormData, setFormData] = React.useState({ distance: "", ad_type: "", latitude: "", longitude: "", image_redirection: "", image_path: "", ad_name: "", })
    const [Image, setImage] = React.useState(null)

    React.useEffect(() => {
        if (type != "createAds") {
            setFormData(SingleRow)
        }
    }, [type])

    const handleChange = (event) => {
        setFormData((prev) => ({ ...prev, ad_type: event.target.value }));
    };
    function handleFileInput(event) {
        console.log('event', event)
        console.log('event files[0]', event.target.files[0])
        if (
            event.target.files[0].type == 'image/jpeg' ||
            event.target.files[0].type == 'image/gif' ||
            event.target.files[0].type == 'image/png'
        ) { setImage(event.target.files[0]) }
        else {
            toast.error('Please select file format jpg, jpeg and png')
        }
    }

    const onChange = (e) => {
        setFormData({ ...FormData, [e.target.name]: e.target.value });
    };

    function validationWebUrl(str) {
        console.log('upcomming data is', str);
        var re =
            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
        // var re= /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
        if (!re.test(str)) {
            return true;
        } else {
            return false;
        }
    }
    function setAdType(typeSting) {
        if (typeSting == 'Straight Ads') {
            return 'A';
        } else if (typeSting == 'LGBTQ (Male-focused) Ads') {
            return 'B';
        } else {
            return 'C';
        }
    }

    async function uploadAd() {
        let isnum = /^\d+$/.test(FormData.distance);
        console.log('FormData', FormData)

        if (Image == {} || Image == null) {
            toast.error('Please select Image to Upload');
        } else if (
            FormData.distance == undefined ||
            FormData.distance == '' ||
            FormData.distance > 500 ||
            !isnum
        ) {
            if (FormData.distance > 500) {
                toast.error('Distance should less than 500 miles');
            } else if (!isnum) {
                toast.error('Please provide distance in numeric value');
            } else {
                toast.error('Please Enter the Distance');
            }
        } else if (FormData.latitude == undefined || FormData.latitude == '') {
            toast.error('Please enter the Latitude');
        } else if (FormData.longitude == undefined || FormData.longitude == '') {
            toast.error('Please Enter the Logitude');
        } else if (
            FormData.image_redirection == undefined ||
            FormData.image_redirection == ''
        ) {
            toast.error('Please Enter the Redirection URL');
        } else if (validationWebUrl(FormData.image_redirection)) {
            toast.error('Please Enter the Valid Redirection URL');
        } else if (FormData.ad_name == undefined || FormData.ad_name == '') {
            toast.error('Please Enter the Ad Name');
        } else if (FormData.ad_type == undefined || FormData.ad_type == '') {
            toast.error('Please Select Ad Type');
        } else {
            console.log('FormData', FormData)
            // FormData.spinner.show();
            // console.log("slug is" , slug)
            var imageRes = await uploadImage(Image);
            console.log('imageRes', imageRes)
            var imagePath = config.ASSETURL.concat(
                imageRes['data']['profile_pic']
            );
            var data = {
                ad_name: FormData.ad_name,
                distance: FormData.distance,
                latitude: FormData.latitude,
                longitude: FormData.longitude,
                image_path: imagePath,
                ad_type: setAdType(FormData.ad_type),
                image_redirection: FormData.image_redirection,
            };
            let response = await PostAds(data);
            console.log('response', response)
            setisShow(false)
            // FormData.dialogRef.close(something);
        }
    }
    async function editAd() {
        let isnum = /^\d+$/.test(FormData.distance);
        console.log('FormData', FormData)

        if (
            FormData.distance == undefined ||
            FormData.distance == '' ||
            FormData.distance > 500 ||
            !isnum
        ) {
            if (FormData.distance > 500) {
                toast.error('Distance should less than 500 miles');
            } else if (!isnum) {
                toast.error('Please provide distance in numeric value');
            } else {
                toast.error('Please Enter the Distance');
            }
        } else if (FormData.latitude == undefined || FormData.latitude == '') {
            toast.error('Please enter the Latitude');
        } else if (FormData.longitude == undefined || FormData.longitude == '') {
            toast.error('Please Enter the Logitude');
        } else if (
            FormData.image_redirection == undefined ||
            FormData.image_redirection == ''
        ) {
            toast.error('Please Enter the Redirection URL');
        } else if (validationWebUrl(FormData.image_redirection)) {
            toast.error('Please Enter the Valid Redirection URL');
        } else if (FormData.ad_name == undefined || FormData.ad_name == '') {
            toast.error('Please Enter the Ad Name');
        } else if (FormData.ad_type == undefined || FormData.ad_type == '') {
            toast.error('Please Select Ad Type');
        } else {
            console.log('FormData', FormData)
            // FormData.spinner.show();
            // console.log("slug is" , slug)
            var imagePath = FormData.image_path
            if (Image) {
                var imageRes = await uploadImage(Image);
                console.log('imageRes', imageRes)
                imagePath = config.ASSETURL.concat(
                    imageRes['data']['profile_pic']
                );
            }
            var data = {
                ad_name: FormData.ad_name,
                distance: FormData.distance,
                latitude: FormData.latitude,
                longitude: FormData.longitude,
                image_path: imagePath,
                ad_type: setAdType(FormData.ad_type),
                image_redirection: FormData.image_redirection,
                is_active: FormData.is_active,
                ad_id: FormData.ad_id,
            };
            let response = await editAds(data);
            console.log('response', response)
            setisShow(false)
            // FormData.dialogRef.close(something);
        }
    }


    return (
        <div className="modalContainer">
            {type == "createAds" ? <div className="uploadAd">
                <div className="d-flex justify-content-around align-items-center">
                    <div className="imageCol">
                        {Image && <div><img alt="image" className="image" src={URL.createObjectURL(Image)} /></div>}
                        <div className="d-flex justify-content-center">
                            <input ref={FileInputRef} onChange={handleFileInput} type="file" name="file" id="file" style={{ display: "none" }}
                                accept="image/gif, image/jpeg, image/png" />
                            <Button className='custom-button uploadButton' variant="contained" onClick={() => FileInputRef.current.click()} mat-raised-button color="primary" >
                                Choose
                            </Button>
                        </div>
                        <span className="supportedResolution">Supported Dimensions (2540 × 967, 3264 × 1255)</span>
                    </div>
                    <div>
                        <div>
                            <div className="example-full-width">
                                <TextField variant="standard" color='primary' label="Ad Title" matInput placeholder="Enter Ad Title" name="ad_name" value={FormData.ad_name}
                                    onChange={onChange} />
                            </div>
                        </div>
                        <div>
                            <div className="example-full-width">
                                <TextField variant="standard" color='primary' label="Distance" matInput placeholder="in miles (max 500 miles)" name="distance" type="number" value={FormData.distance}
                                    onChange={onChange} />
                            </div>
                        </div>
                        <div>
                            <div className="example-full-width">
                                <TextField variant="standard" color='primary' label="Latitude" type="number" matInput placeholder="Enter Latitude" name="latitude" inputProps={{ step: 0.000001, pattern: "/^[0-9]+(\.[0-9]{1,2})?$/" }}
                                    value={FormData.latitude}
                                    onChange={onChange} />
                            </div>
                        </div>
                        <div>
                            <div className="example-full-width">
                                <TextField variant="standard" color='primary' label="Longitude" type="number" matInput placeholder="Enter Longitude" name="longitude" inputProps={{ step: 0.000001, pattern: "/^[0-9]+(\.[0-9]{1,2})?$/" }} value={FormData.longitude}
                                    onChange={onChange} />
                            </div>
                        </div>
                        <div>
                            <div className="example-full-width">
                                <FormControl variant="filled" sx={{ width: "90%" }}>
                                    <InputLabel id="demo-simple-select-filled-label">Ad Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={FormData.ad_type}
                                        onChange={handleChange}
                                    >

                                        {typeOptions.map((key) => <MenuItem value={key}>{key}</MenuItem>)}

                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div>
                            <div className="example-full-width">
                                <TextField variant="standard" color='primary' label="Ad Redirection" matInput placeholder="https://www.example.com" name="image_redirection" value={FormData.image_redirection}
                                    onChange={onChange} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <Button variant="contained" onClick={uploadAd} className='custom-button' mat-raised-button color="primary"> Create </Button>
                </div>





            </div > :
                <div className="uploadAd">
                    <div className="d-flex justify-content-around align-items-center">
                        <div className="imageCol">
                            {Image ? <div><img alt="image" className="image" src={URL.createObjectURL(Image)} /></div> : <div><img alt="image" className="image" src={FormData.image_path} /></div>}
                            <div className="d-flex justify-content-center">
                                <input ref={FileInputRef} onChange={handleFileInput} type="file" name="file" id="file" style={{ display: "none" }}
                                    accept="image/gif, image/jpeg, image/png" />
                                <Button className='custom-button uploadButton' variant="contained" onClick={() => FileInputRef.current.click()} mat-raised-button color="primary" >
                                    Choose
                                </Button>
                            </div>
                            <span className="supportedResolution">Supported Dimensions (2540 × 967, 3264 × 1255)</span>
                        </div>
                        <div>
                            <div>
                                <div className="example-full-width">
                                    <TextField variant="standard" color='primary' label="Ad Title" matInput placeholder="Enter Ad Title" name="ad_name" value={FormData.ad_name}
                                        onChange={onChange} />
                                </div>
                            </div>
                            <div>
                                <div className="example-full-width">
                                    <TextField variant="standard" color='primary' label="Distance" matInput placeholder="in miles (max 500 miles)" name="distance" type="number" value={FormData.distance}
                                        onChange={onChange} />
                                </div>
                            </div>
                            <div>
                                <div className="example-full-width">
                                    <TextField variant="standard" color='primary' label="Latitude" type="number" matInput placeholder="Enter Latitude" name="latitude" inputProps={{ step: 0.000001, pattern: "/^[0-9]+(\.[0-9]{1,2})?$/" }}
                                        value={FormData.latitude}
                                        onChange={onChange} />
                                </div>
                            </div>
                            <div>
                                <div className="example-full-width">
                                    <TextField variant="standard" color='primary' label="Longitude" type="number" matInput placeholder="Enter Longitude" name="longitude" inputProps={{ step: 0.000001, pattern: "/^[0-9]+(\.[0-9]{1,2})?$/" }} value={FormData.longitude}
                                        onChange={onChange} />
                                </div>
                            </div>
                            <div>
                                <div className="example-full-width">
                                    <FormControl variant="filled" sx={{ width: "90%" }}>
                                        <InputLabel id="demo-simple-select-filled-label">Ad Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            value={FormData.ad_type}
                                            onChange={handleChange}
                                        >

                                            {typeOptions.map((key) => <MenuItem value={key}>{key}</MenuItem>)}

                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div>
                                <div className="example-full-width">
                                    <TextField variant="standard" color='primary' label="Ad Redirection" matInput placeholder="https://www.example.com" name="image_redirection" value={FormData.image_redirection}
                                        onChange={onChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <Button variant="contained" onClick={editAd} className='custom-button' mat-raised-button color="primary"> Save </Button>
                    </div>





                </div >}
        </div >
    )
}

export default CreatAds