import axios from 'axios';
import config from "./index"
const instance = axios.create({ baseURL: config.APIURL });

instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

instance.setToken = (accessToken) => {
    instance.defaults.headers.Authorization = `Bearer ${accessToken}`;
};

export default instance;
