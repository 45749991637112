module.exports = {
    prod: {
        production: true,
        APIURL: 'https://aglanceapp.com/api',
        ASSETURL: 'https://aglanceapp.com/'
    },
    dev: {
        production: false,
        // APIURL: 'http://localhost:2020/api',
        // ASSETURL: 'http://localhost:2020/',
        APIURL: 'https://glanceapp.inspeero.com/api',
        ASSETURL: 'https://glanceapp.inspeero.com/',
    }
}
const config = process.env.NODE_ENV === 'production' || true ? module.exports.prod : module.exports.dev;
console.log('config', config)
module.exports = config