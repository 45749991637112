import React from 'react'
import "./Ads.css"
import { UpdateRefer, editAds, getAllAds, getAllRefer } from '../../service/APIRequest'
import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Pagination } from '@mui/material';
import CreatAds from './CreatAds';
import config from '../../config';
import { toast } from 'react-hot-toast';

const Refer = () => {
    console.log('config', config)
    const navigation = useNavigate()
    const [Lists, setLists] = React.useState([])
    const [isShow, setisShow] = React.useState(false)
    const [ImageSRC, setImageSRC] = React.useState(null)
    const [page, setPage] = React.useState(0)
    const itemsPerPage = 10
    const [type, settype] = React.useState("createAds")
    const [SingleRow, setSingleRow] = React.useState({})
    const [open, setOpen] = React.useState(false);
    const [referrerdata,setReferData]=  React.useState([]);
    const [colorchange,setColorChange]=React.useState(false);
    React.useEffect(() => {
        // let currentUser = JSON.parse(localStorage.getItem('glanceuser'));
        // if (!currentUser) {
        //     toast.error("Please Login again!")
        //     navigation("/")

        // }
        // console.log('currentUser', currentUser)
        fetchData()
    }, [isShow, page])

    function logout() {
        localStorage.removeItem('glanceuser');
        navigation("/")
    }
    const fetchData = async () => {

        let response = await getAllRefer({ page })
        console.log('response', response)
        if (response.code === 200) {
            console.log('response=====', response.data)
            setLists(response.data)
        }
        console.log('response fetchdata', response)
    }


   

    // Pagination

    const pageCount = Math.ceil(Lists.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event, value) => {
        console.log('value', value)
        console.log('event', event)
        setPage(value - 1)
        // const newOffset = (event.selected * itemsPerPage) % Lists.length;
        console.log(
            // `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        // setItemOffset(newOffset);
    };

    // Modal dialog
    const handleClose = () => {
        setisShow(false);
        settype("createAds")
    }
    const OnEditHandle = (data) => {
        settype("EditAds")
        var passData = {
            ad_name: data.ad_name,
            ad_type: data.ad_type_string,
            distance: data.distance,
            image_path: data.image_path,
            image_redirection: data.image_redirection,
            latitude: data.location.coordinates[1],
            longitude: data.location.coordinates[0],
            is_active: data.is_active,
            ad_id: data._id,
        };
        setSingleRow(passData)
        setisShow(true)
    }
    const handleClickOpen = () => {
        console.log("open poup");
        setOpen(true);
      };
    
      const handleClosepoup = () => {
        setOpen(false);
        window.location.reload(false);
      };
    async function updateReferStatus(referdata) {
        console.log('stats', referdata)
        setOpen(true);
        setReferData(referdata);
        // setLists((prev) => prev.map((single) =>
        //     single._id == ad_id ? { ...single, 'is_active': stats } : single
        // ))
        // await UpdateRefer({
        //     referrer
        // });
       
        // fetchData()
    }
    async function handleReferData(referrer){
        console.log(referrer,"referdata")
       
        let response= await UpdateRefer({
            referrer
        });
        console.log(response,"response9999999999")
        if(response.code===200){

            setOpen(false)
            fetchData()
            window.location.reload(false);
        }
       
        
      
    }
    console.log('Lists', Lists)
    return (
        <div className="dash-container" style={{ display: "flex", flexDirection: "column" }}>
            <div className="dash-header-conniner" style={{}} >
                <div className="dash-heading">
                    <h1 className="dash-heading-text">Glance Refer<Button color="secondary" onClick={() => navigation("/dashboard")} className='float-end'>Ads</Button> <Button color="secondary" onClick={() => navigation("/subscription")} className='float-end'>Free Subscription</Button></h1>
                </div>
                <Button variant="contained" className="dash-add-button custom-button" mat-raised-button color="primary"
                    onClick={logout}
                >Logout</Button>
                {/* <Button variant="contained" className=" dash-add-button custom-button" mat-raised-button color="primary"
                    onClick={() => setisShow(true)}
                >+ Create Ad</Button> */}
                <Button variant="contained" className=" dash-refresh-button custom-button" mat-raised-button color="primary"
                    onClick={fetchData}>Refresh</Button>
            </div>
            <div className="dash-table-container ">
                <div className="table-responsive">
                    <table className="material table"
                    //      [rows]="rows" [footerHeight]="50" [rowHeight]="50" [columnMode]="'force'"
                    //     [externalPaging]="true" [limit]="limit" [count]="count" [scrollbarV]="false" (page)="setPage($event)"
                    //     [offset]="tableOffset" [messages]="{emptyMessage: 'No Such Ad Found', totalMessage: 'Total Ads'}"
                    // [headerHeight]="50" [loadingIndicator]="internal_load"
                    >
                        <thead>
                            <tr className='header-style'>
                                <td scope="col" className=' width-100'>Name</td>
                                <td scope="col" className=' width-100'>Email</td>
                                <td scope="col" className=' width-100'>Total Referred</td>
                                <td scope="col" className=' width-100'>Mark as Rewarded</td>
                                {/* <td scope="col" className=' width-250'>Title</td>
                                <td scope="col" className=' width-100'>Longitude</td>
                                <td scope="col" className=' width-100'>Latitude</td>
                                <td scope="col" className=' width-100'>Distance</td>
                                <td scope="col" className=' width-100'>Image</td>
                                <td scope="col" className=' width-250'>Image Redirection</td> */}
                                {/* <td scope="col">Ad Type</td> */}
                                {/* <td scope="col" className=' width-100'>Activated</td> */}
                                {/* <td scope="col" className=' width-150'>Action</td> */}
                            </tr>
                        </thead>
                        <tbody>
                           
                            {Lists?.length == 0 ? <div className='no-data'>No data found</div> : Lists?.map((user) => {
                                return (<tr className='position-relative tbody-style'>
                                    <td >
                                        <div className="ellipsis">
                                            <span className="tooltiptext">{user.referrer.name}</span>
                                            {user.referrer.name}
                                        </div>
                                    </td>
                                    <td >
                                        <div className="ellipsis">
                                            <span className="tooltiptext">{user.referrer.email}</span>
                                            {user.referrer.email}
                                        </div>
                                    </td>
                                    <td >
                                        <div className="ellipsis">
                                            <span className="tooltiptext">{user.count}</span>
                                            {user.count}
                                        </div>
                                    </td>
                                    <td className=' width-100'>
                                        <label className="switch">
                                            <input type="checkbox" disabled={user.count < 5} onChange={() => updateReferStatus(user.referrer._id)} />
                                            {/* <input type="checkbox" disabled={user.count < 5} onChange={() => <input type="checkbox" disabled={user.count < 5} onChange={handleClickOpen} />} /> */}

                                            <span className= "slider round"></span>
                                        </label>
                                    </td>
                                    {/* <td >
                                        <div className="ellipsis width-100">
                                            <span className="tooltiptext">{ad.location.coordinates[0]}</span>
                                            {ad.location.coordinates[0]}
                                        </div>
                                    </td>
                                    <td >
                                        <div className="ellipsis width-100">
                                            <span className="tooltiptext">{ad.location.coordinates[1]}</span>
                                            {ad.location.coordinates[1]}
                                        </div>
                                    </td>
                                    <td >
                                        <div className="ellipsis width-100">
                                            <span className="tooltiptext">{ad.distance}</span>
                                            {ad.distance}
                                        </div>
                                    </td>
                                    <td className=' width-100'>
                                        <img className="dash-table-image" onClick={() => setImageSRC(ad.image_path)}
                                            src={ad.image_path}
                                            width="50" height="50" alt="Computer Hope" />
                                    </td>
                                    <td className=' width-250'>
                                        <div className="ellipsis width-250">
                                            <span className="tooltiptext">{ad.image_redirection}</span>
                                            {ad.image_redirection}
                                        </div>
                                    </td>
                                    <td >
                                        <div className="ellipsis">
                                            <span className="tooltiptext">{ad.ad_type_string}</span>
                                            {ad.ad_type_string}
                                        </div>
                                    </td>
                                    <td className=' width-100'>
                                        {console.log('{ad.is_active}', ad.is_active)}
                                        <label className="switch">
                                            <input type="checkbox" checked={ad.is_active} onChange={() => updateAdStatus(ad.is_active ? false : true, 'is_active', ad._id, ad)} />


                                            <span className="slider round"></span>
                                        </label>
                                    </td>
                                    <td className=' width-150'>
                                        <div style={{ display: "flex", flexDirection: "row", gap: 1 }}>
                                            <button type="button" className="btn btn-danger delete-button"
                                            >Delete</button>
                                            <button type="button" className="btn btn-primary edit-button" onClick={() => OnEditHandle(ad)}
                                            >Edit</button>
                                        </div>
                                    </td> */}
                                </tr>
                                
                                )
                            })}
                        </tbody>

                    </table>
                    <div >
                        <div className='pagination-text float-start'>{Lists.length} Total Refers</div>
                        <div className='float-end'>
                            <Pagination count={pageCount || 1} showFirstButton showLastButton onChange={handlePageClick} />



                        </div>
                    </div>
                </div>
            </div >
            <div className={{}}>
                <Modal
                    open={isShow}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="model-css">
                        <CreatAds isShow={isShow} setisShow={setisShow} SingleRow={SingleRow} type={type} />
                    </Box>
                </Modal>
                <Modal
                    open={Boolean(ImageSRC)}
                    onClose={() => setImageSRC(null)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="model-css">
                        <img src={ImageSRC} width="100%" height="100%" alt="ad" />
                    </Box>
                </Modal>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Confirm Your Action"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to confirm your action?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClosepoup}>Cancel</Button>
                <Button onClick={() => handleReferData(referrerdata)} autoFocus>
                    Ok
                </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

export default Refer