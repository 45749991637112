import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import toast from "react-hot-toast";
import { addFreeSubscription } from "../../service/APIRequest";

const AddFreeSubscription = ({ isShow, setisShow }) => {
  const [FormData, setFormData] = React.useState({
    email: "",
    subscriptionId: "",
    months: null,
  });

  const monthOptions = [1, 2, 3];
  const subscriptionOptions = [
    {
      value: "glance_monthly",
      label: "Plus",
    },
    {
      value: "glance_premium_mothly",
      label: "Premium",
    },
  ];

  const AddSubscriptionToUser = async () => {
    if (FormData.email == undefined || FormData.email == "") {
      toast.error("Please Enter the user email address");
    } else if (
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        FormData.email
      )
    ) {
      toast.error("Please Enter valid email address");
    } else if (
      FormData.subscriptionId == undefined ||
      FormData.subscriptionId == ""
    ) {
      toast.error("Please select the subscription plan");
    } else if (FormData.months == null || FormData.months == 0) {
      toast.error("Please select the subscription month");
    } else {
      var data = {
        email: FormData.email,
        subscriptionId: FormData.subscriptionId,
        months: FormData.months,
      };
      let response = await addFreeSubscription(data);
      if (response.data) {
        toast.success(response.msg);
        setisShow(false);
      }
    }
  };

  const onChange = (e) => {
    setFormData({ ...FormData, [e.target.name]: e.target.value });
  };
  const handleChangeSubscriptionId = (event) => {
    setFormData((prev) => ({ ...prev, subscriptionId: event.target.value }));
  };
  const handleChangeMonths = (event) => {
    setFormData((prev) => ({ ...prev, months: event.target.value }));
  };

  return (
    <div className="modalContainer p-4">
      <h3 className="dash-heading-text">Add Free Subscription </h3>
      <hr />
      <div className="d-flex justify-content-around align-items-center">
        <div style={{ width: "100%" }}>
          <div>
            <div
              className="example-full-width"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.06)" }}
            >
              <TextField
                variant="standard"
                color="primary"
                label="Email"
                matInput
                placeholder="Enter user email address"
                name="email"
                value={FormData.email}
                onChange={onChange}
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root": {
                    paddingLeft: "12px",
                  },
                  "& .MuiInput-input": {
                    paddingBottom: "13px",
                    paddingLeft: "12px",
                  },
                }}
              />
            </div>
          </div>
          <div>
            <div className="example-full-width">
              <FormControl variant="filled" sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-filled-label">
                  Subscription Plan
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={FormData.subscriptionId}
                  onChange={handleChangeSubscriptionId}
                >
                  {subscriptionOptions.map((subscription) => (
                    <MenuItem value={subscription.value}>
                      {subscription.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div>
            <div className="example-full-width">
              <FormControl variant="filled" sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-filled-label">
                  Month
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={FormData.months}
                  onChange={handleChangeMonths}
                >
                  {monthOptions.map((key) => (
                    <MenuItem value={key}>{key}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-center">
            <Button
              variant="contained"
              onClick={AddSubscriptionToUser}
              className="custom-button"
              mat-raised-button
              color="primary"
            >
              {" "}
              Add{" "}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFreeSubscription;
