import { Box, Button, Modal, Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CreatAds from "../Ads/CreatAds";
import React from "react";
import AddFreeSubscription from "./AddFreeSubscription";
import { deactivateFreeSubscription, freeSubscriptionListing } from "../../service/APIRequest";
import toast from "react-hot-toast";

const Subscription = () => {
  const navigation = useNavigate();
  const [isShow, setisShow] = React.useState(false);
  const [Lists, setLists] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const itemsPerPage = 10;
  const [pageCount, setPageCount] = React.useState(Math.ceil(Lists.length / itemsPerPage))
  const [userCounts, setuserCount] = React.useState(0)

//   const pageCount = setPageCount(Math.ceil(Lists.length / itemsPerPage));

  React.useEffect(() => {
    fetchData();
  }, [isShow, page]);

  const fetchData = async () => {
    let response = await freeSubscriptionListing({ page, limit: itemsPerPage });
    if ((response.code = 200)) {
      setLists(response.data);
      setuserCount(response.total)
      setPageCount(Math.ceil(response.total / itemsPerPage))
    }
  };
  function logout() {
    localStorage.removeItem("glanceuser");
    navigation("/");
  }

  const handleClose = () => {
    setisShow(false);
  };

  const DeactivateUSer = async(subscriptionId, userId) => {
    let response = await deactivateFreeSubscription({subscription_id: subscriptionId, free_subscribed_user_Id: userId})
    if ((response.code = 200)) {
        toast.success("User deactivated successfully!")
        fetchData()
      }
  }

  const handlePageClick = (event, value) => {
        console.log('value', value)
        console.log('event', event)
        setPage(value)
    }

  return (
    <div
      className="dash-container"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="dash-header-conniner" style={{}}>
        <div className="dash-heading">
          <h1 className="dash-heading-text">
            Glance Free Subscription
            <Button
              color="secondary"
              onClick={() => navigation("/dashboard")}
              className="float-end"
            >
              Ads
            </Button>
            <Button
              color="secondary"
              onClick={() => navigation("/refer")}
              className="float-end"
            >
              Refer
            </Button>
          </h1>
        </div>
        <Button
          variant="contained"
          className="dash-add-button custom-button"
          mat-raised-button
          color="primary"
          onClick={logout}
        >
          Logout
        </Button>
        <Button
          variant="contained"
          className=" dash-add-button custom-button"
          mat-raised-button
          color="primary"
          onClick={() => setisShow(true)}
        >
          + Add Subscription
        </Button>
        <Button
          variant="contained"
          className=" dash-refresh-button custom-button"
          mat-raised-button
          color="primary"
          onClick={fetchData}
        >
          Refresh
        </Button>
      </div>
      <div className="dash-table-container ">
        <div className="table-responsive">
          <table className="material table">
            <thead>
              <tr className="header-style">
                <td scope="col" className=" width-250">
                  User Email
                </td>
                <td scope="col" className=" width-150">
                  Subscription Type
                </td>
                <td scope="col" className=" width-100">
                  Start Date
                </td>
                <td scope="col" className=" width-100">
                  End Date
                </td>
                <td scope="col" className=" width-150">
                  Action
                </td>
              </tr>
            </thead>
            <tbody>
              {Lists?.length == 0 ? (
                <div className="no-data">No data found</div>
              ) : (
                Lists.map((subscribedUser) => {
                  return (
                    <tr className="position-relative tbody-style">
                      <td>
                        <div className="ellipsis" style={{margin: '5px 0'}}>
                          <span className="tooltiptext">
                            {subscribedUser.user_details.email}
                          </span>
                          {subscribedUser.user_details.email}
                        </div>
                      </td>
                      <td>
                        <div className="ellipsis width-150" style={{margin: '5px 0'}}>
                          <span className="tooltiptext">
                          {subscribedUser.subscription_id === "glance_premium_mothly" ? "Premium" : subscribedUser.subscription_id === "glance_monthly" ? "Plus" : ""}
                          </span>
                          {subscribedUser.subscription_id === "glance_premium_mothly" ? "Premium" : subscribedUser.subscription_id === "glance_monthly" ? "Plus" : ""}
                        </div>
                      </td>
                      <td>
                        <div className="ellipsis width-150" style={{margin: '5px 0'}}>
                          <span className="tooltiptext">
                            {subscribedUser.start_date.split('T')[0]}
                          </span>
                          {subscribedUser.start_date.split('T')[0]}
                        </div>
                      </td>
                      <td>
                        <div className="ellipsis width-150" style={{margin: '5px 0'}}>
                          <span className="tooltiptext">
                            {subscribedUser.end_date.split('T')[0]}
                          </span>
                          {subscribedUser.end_date.split('T')[0]}
                        </div>
                      </td>
                      <td>
                        <div>
                          {/* (click)="onDelete(row)" */}
                          <button
                            type="button"
                            className="btn btn-danger"
                            style={{ width: "90px !important", fontSize: '12px' }}
                            onClick={() => DeactivateUSer(subscribedUser._id, subscribedUser.user_id)}
                          >
                            Deactivate
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          <div>
            <div className="pagination-text float-start">
              {userCounts} Total Subscribed Users
            </div>
            <div className="float-end">
              <Pagination
                count={pageCount || 1} 
                showFirstButton
                showLastButton
                onChange={handlePageClick}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={{}}>
        <Modal
          open={isShow}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="model-css">
            <AddFreeSubscription
              isShow={isShow}
              setisShow={setisShow}
            ></AddFreeSubscription>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default Subscription;
