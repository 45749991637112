import { Toaster } from 'react-hot-toast';
import './App.css';
import Navigation from './component/Navigation';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5', // replace with your desired primary color
    },
  },
});
function App() {
  return (

    <ThemeProvider theme={theme} >
      <Navigation />
      <Toaster
        position="bottom-center"
        reverseOrder={false}
      />
    </ThemeProvider>
  );
}

export default App;
