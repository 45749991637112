import React from 'react'
import "./login.css"
import { LoginReqAPI } from '../../service/APIRequest';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [isLoding, setisLoding] = React.useState(false)
  const naviagte = useNavigate()
  const onSubmit = async (event) => {
    event.preventDefault()
    // naviagte("/dashboard")
    setisLoding(true)
    console.log('event', event)
    let response = await LoginReqAPI({ email: event.target[0].value, password: event.target[1].value })
    console.log('response', response)
    if (response) {
      naviagte("/dashboard")
    }
    setisLoding(false)

  };

  return (
    <div className="login-page">
      <form className="form" onSubmit={onSubmit} method='POST'>
        <h1 className="headerContainer">Glance</h1>
        <div className="login-form" >
          <input required="true" type="text" placeholder="Username" name="useraEmail" />
          <input required="true" type="password" placeholder="Password" name="userPass" />
          <button type='submit'>
            {isLoding ? <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div> :
              "Login"}
          </button>
        </div >
      </form >
    </div >
  )
}

export default Login