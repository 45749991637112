import React from 'react'
import "./Ads.css"
import { deleteAd, editAds, getAllAds } from '../../service/APIRequest'
import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import { Box, Button, Modal, Pagination } from '@mui/material';
import CreatAds from './CreatAds';
import config from '../../config';
import { toast } from 'react-hot-toast';

const Ads = () => {
    console.log('config', config)
    const navigation = useNavigate()
    const [Lists, setLists] = React.useState([])
    const [isShow, setisShow] = React.useState(false)
    const [ImageSRC, setImageSRC] = React.useState(null)
    const [page, setPage] = React.useState(0)
    // const [itemOffset, setItemOffset] = React.useState(0);
    const itemsPerPage = 10
    const [type, settype] = React.useState("createAds")
    const [SingleRow, setSingleRow] = React.useState({})
    React.useEffect(() => {
        // let currentUser = JSON.parse(localStorage.getItem('glanceuser'));
        // if (!currentUser) {
        //     toast.error("Please Login again!")
        //     navigation("/")

        // }
        // console.log('currentUser', currentUser)
        fetchData()
    }, [isShow, page])

    function logout() {
        localStorage.removeItem('glanceuser');
        navigation("/")
    }
    const fetchData = async () => {

        let response = await getAllAds({ page })
        if (response.code = 200) {
            setLists(response.data)
        }
        console.log('response', response)
    }


    console.log('Lists', Lists)

    // Pagination

    const pageCount = Math.ceil(Lists.length / itemsPerPage);
    // Invoke when user click to request another page.
    const handlePageClick = (event, value) => {
        console.log('value', value)
        console.log('event', event)
        setPage(value - 1)
        // const newOffset = (event.selected * itemsPerPage) % Lists.length;
        console.log(
            // `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        // setItemOffset(newOffset);
    };

    // Modal dialog
    const handleClose = () => {
        setisShow(false);
        settype("createAds")
    }
    const OnEditHandle = (data) => {
        settype("EditAds")
        var passData = {
            ad_name: data.ad_name,
            ad_type: data.ad_type_string,
            distance: data.distance,
            image_path: data.image_path,
            image_redirection: data.image_redirection,
            latitude: data.location.coordinates[1],
            longitude: data.location.coordinates[0],
            is_active: data.is_active,
            ad_id: data._id,
        };
        setSingleRow(passData)
        setisShow(true)
    }
    function updateAdStatus(stats, cell, ad_id, ad) {
        console.log('stats', stats)
        setLists((prev) => prev.map((single) =>
            single._id == ad_id ? { ...single, 'is_active': stats } : single
        ))
        editAds({
            ad_id: ad._id,
            ad_name: ad.ad_name,
            image_path: ad.image_path,
            image_redirection: ad.image_redirection,
            is_active: stats,
            ad_type: ad.ad_type,
            distance: ad.distance,
            latitude: ad.location.coordinates[1],
            longitude: ad.location.coordinates[0],
        });
    }
    const OnDeleteAd =  async (ad) => {
    console.log(ad,"deleteAd");
    let response = await deleteAd(ad);
    console.log(response);
        if(response.msg==="SUCCESS"){
            toast.success("Ad Deleted");
            window.location.reload(false);
        }
    }
    return (
        <div className="dash-container" style={{ display: "flex", flexDirection: "column" }}>
            <div className="dash-header-conniner" style={{}} >
                <div className="dash-heading">
                    <h1 className="dash-heading-text">Glance Ads  <Button color="secondary" onClick={() => navigation("/refer")} className='float-end'>Refer</Button>
                    <Button color="secondary" onClick={() => navigation("/subscription")} className='float-end'>Free Subscription</Button></h1>


                </div>
                <Button variant="contained" className="dash-add-button custom-button" mat-raised-button color="primary"
                    onClick={logout}
                >Logout</Button>
                <Button variant="contained" className=" dash-add-button custom-button" mat-raised-button color="primary"
                    onClick={() => setisShow(true)}
                >+ Create Ad</Button>
                <Button variant="contained" className=" dash-refresh-button custom-button" mat-raised-button color="primary"
                    onClick={fetchData}>Refresh</Button>
            </div>
            <div className="dash-table-container ">
                <div className="table-responsive">
                    <table className="material table"
                    //      [rows]="rows" [footerHeight]="50" [rowHeight]="50" [columnMode]="'force'"
                    //     [externalPaging]="true" [limit]="limit" [count]="count" [scrollbarV]="false" (page)="setPage($event)"
                    //     [offset]="tableOffset" [messages]="{emptyMessage: 'No Such Ad Found', totalMessage: 'Total Ads'}"
                    // [headerHeight]="50" [loadingIndicator]="internal_load"
                    >
                        <thead>
                            <tr className='header-style'>
                                <td scope="col" className=' width-250'>Title</td>
                                <td scope="col" className=' width-100'>Longitude</td>
                                <td scope="col" className=' width-100'>Latitude</td>
                                <td scope="col" className=' width-100'>Distance</td>
                                <td scope="col" className=' width-100'>Image</td>
                                <td scope="col" className=' width-250'>Image Redirection</td>
                                <td scope="col">Ad Type</td>
                                <td scope="col" className=' width-100'>Activated</td>
                                <td scope="col" className=' width-150'>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            {Lists?.length == 0 ? <div className='no-data'>No data found</div> : Lists.map((ad) => {
                                return (<tr className='position-relative tbody-style'>
                                    <td >
                                        <div className="ellipsis">
                                            <span className="tooltiptext">{ad.ad_name}</span>
                                            {ad.ad_name}
                                        </div>
                                    </td>
                                    <td >
                                        <div className="ellipsis width-100">
                                            <span className="tooltiptext">{ad.location.coordinates[0]}</span>
                                            {ad.location.coordinates[0]}
                                        </div>
                                    </td>
                                    <td >
                                        <div className="ellipsis width-100">
                                            <span className="tooltiptext">{ad.location.coordinates[1]}</span>
                                            {ad.location.coordinates[1]}
                                        </div>
                                    </td>
                                    <td >
                                        <div className="ellipsis width-100">
                                            <span className="tooltiptext">{ad.distance}</span>
                                            {ad.distance}
                                        </div>
                                    </td>
                                    <td className=' width-100'>
                                        <img className="dash-table-image" onClick={() => setImageSRC(ad.image_path)}
                                            src={ad.image_path}
                                            width="50" height="50" alt="Computer Hope" />
                                    </td>
                                    <td className=' width-250'>
                                        <div className="ellipsis width-250">
                                            <span className="tooltiptext">{ad.image_redirection}</span>
                                            {ad.image_redirection}
                                        </div>
                                    </td>
                                    <td >
                                        <div className="ellipsis">
                                            <span className="tooltiptext">{ad.ad_type_string}</span>
                                            {ad.ad_type_string}
                                        </div>
                                    </td>
                                    <td className=' width-100'>
                                        {console.log('{ad.is_active}', ad.is_active)}
                                        <label className="switch">
                                            <input type="checkbox" checked={ad.is_active} onChange={() => updateAdStatus(ad.is_active ? false : true, 'is_active', ad._id, ad)} />


                                            <span className="slider round"></span>
                                        </label>
                                    </td>
                                    <td className=' width-150'>
                                        <div style={{ display: "flex", flexDirection: "row", gap: 1 }}>
                                            {/* (click)="onDelete(row)" */}
                                            <button type="button" className="btn btn-danger delete-button" onClick={() => OnDeleteAd(ad)}
                                            >Delete</button>
                                            <button type="button" className="btn btn-primary edit-button" onClick={() => OnEditHandle(ad)}
                                            >Edit</button>
                                        </div>
                                    </td>
                                </tr>)
                            })}
                        </tbody>

                    </table>
                    <div >
                        <div className='pagination-text float-start'>{Lists.length} Total Ads</div>
                        <div className='float-end'>
                            <Pagination count={pageCount || 1} showFirstButton showLastButton onChange={handlePageClick} />

                            {/* <ReactPaginate
                                nextLabel="next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={pageCount}
                                previousLabel="< previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            /> */}

                        </div>
                    </div>
                </div>
            </div >
            <div className={{}}>
                <Modal
                    open={isShow}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="model-css">
                        <CreatAds isShow={isShow} setisShow={setisShow} SingleRow={SingleRow} type={type} />
                    </Box>
                </Modal>
                <Modal
                    open={Boolean(ImageSRC)}
                    onClose={() => setImageSRC(null)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="model-css">
                        <img src={ImageSRC} width="100%" height="100%" alt="ad" />
                    </Box>
                </Modal>
            </div>
        </div >
    )
}

export default Ads