import { toast } from "react-hot-toast";
import API from "../config/axios"
import { useNavigate } from "react-router-dom/dist";


/**
 * Authentication
 */

/**
 * Fuction for Login API
 * @param {*} data
 * @returns
 */
export const LoginReqAPI = async (data) => {
  let response = await API.post('/users/login', { ...data, device_os: "1", device_os_version: "admin1111222" });
  console.log('response', response.data)
  if (response.data.code === 200) {
    if (response.data["data"]["role"] === 'ADMIN') {
      localStorage.setItem('glanceuser', JSON.stringify(response.data["data"]));
      // this.router.navigate(['/dashboard']);
      return response.data;
    }
    return false

  } else {
    // this._snackBar.open("Login Failed", "UNDO", {
    //   duration: 2000      
    // });
    toast.error("Login Failed");
    return false;
  }
};

/**
 * Ads flow
 */

/**
 * Fuction for Create Ads API
 * @param {*} data
 * @returns
 */
export const PostAds = async (data) => {

  let currentUser = JSON.parse(localStorage.getItem('glanceuser'));

  console.log(currentUser,"currentUser");
  let payload = {
    ...data,
    full_location: "undefined",

  }
  console.log('payload', payload)
 
    let response = await API.post('/ad/post_ads', payload, {
      headers: {
        user_id: currentUser['_id'],
        token: currentUser['token'],
      }
    });
    console.log('response', response)
    if (response.data.code === 200) {
      return response.data;
      //  if (this.user["data"]["role"] == 'ADMIN') {
      //   localStorage.setItem('glanceuser', JSON.stringify(this.user["data"]));
      //   this.router.navigate(['/dashboard']);
      // }
    } else {
      // this._snackBar.open("Login Failed", "UNDO", {
      //   duration: 2000      
      // });
      toast.error(response?.data?.data || response?.data?.message || "Something went wrong");
      return false;
    }
  


};

/**
 * Fuction for Edit Ads API
 * @param {*} data
 * @returns
 */
export const editAds = async (data) => {
  let currentUser = JSON.parse(localStorage.getItem('glanceuser'));
  let payload = {
    ...data,

  }
  let response = await API.post('/ad/edit_ad', payload, {
    headers: {
      user_id: currentUser['_id'],
      token: currentUser['token'],
    }
  });
  console.log('response', response)
  if (response.data.code === 200) {
    return response.data;

  } else {
    toast.error(response?.data?.data || response?.data?.message || "Something went wrong");
    return false;
  }
};

/**
 * Fuction for get all Ads API
 * @param {*} data
 * @returns
 */
export const getAllAds = async (data) => {
  console.log('data', data)
  let currentUser = JSON.parse(localStorage.getItem('glanceuser'));
  let payload = {
    ...data,
    page: data.page,
    limit: 10,

  }
  let response = await API.post('/ad/get_all_ads', payload, {
    headers: {
      user_id: currentUser['_id'],
      token: currentUser['token'],
    }
  });
  console.log('response', response)
  if (response.data.code === 200) {
    return response.data;

  } else {
    toast.error(response?.data?.data || response?.data?.message || "Something went wrong");
    return false;
  }
};

/**
 * Fuction for get all REFER API
 * @param {*} data
 * @returns
 */
export const getAllRefer = async (data) => {
  let currentUser = JSON.parse(localStorage.getItem('glanceuser'));
  let payload = {
    ...data,
    page: data.page,
    limit: 10,

  }
  let response = await API.post('/users/get-refer', payload, {
    headers: {
      user_id: currentUser['_id'],
      token: currentUser['token'],
    }
  });
  console.log('response', response)
  if (response.data.code === 200) {
    return response.data;

  } else {
    toast.error(response?.data?.data || response?.data?.message || "Something went wrong");
    return false;
  }
};

/**
 * Fuction for update REFER API
 * @param {*} data
 * @returns
 */
export const UpdateRefer = async (data) => {
  let currentUser = JSON.parse(localStorage.getItem('glanceuser'));
  let payload = {
    ...data,


  }
  console.log(payload,"payload api")
  let response = await API.post('/users/mark-refer', payload, {
    headers: {
      user_id: currentUser['_id'],
      token: currentUser['token'],
    }
  });
  console.log('response api', response)
  if (response.data.code === 200) {
    return response.data;

  } else {
    toast.error(response?.data?.data || response?.data?.message || "Something went wrong");
    return false;
  }
};

/**
 * Fuction for Upload Image
 * @param {*} data
 * @returns
 */
export const uploadImage = async (data) => {
  let currentUser = JSON.parse(localStorage.getItem('glanceuser'));

  let formdata = new FormData();
  console.log('data uploadImage---->', data);
  formdata.append('image_data', data); //here
  formdata.append('is_profile', 'ad'); //and here

  let response = await API.post('/users/update_user_profile_picture', formdata, {
    headers: {
      user_id: currentUser['_id'],
      token: currentUser['token'],
    }
  });
  console.log('response', response)
  if (response.data.code === 200) {
    return response.data;

  } else {
    toast.error(response?.data?.data || response?.data?.message || "Something went wrong");
    return false;
  }
};

/**
 * Fuction for get settings
 * @param {*} data
 * @returns
 */
export const getSettings = async (data) => {
  let currentUser = JSON.parse(localStorage.getItem('glanceuser'));

  let response = await API.get('/settings/get_settings', {
    headers: {
      user_id: currentUser['_id'],
      token: currentUser['token'],
    }
  });
  console.log('response', response)
  if (response.data.code === 200) {
    return response.data;

  } else {
    toast.error(response?.data?.data || response?.data?.message || "Something went wrong");
    return false;
  }
};

/**
 * Fuction for update settings
 * @param {*} data
 * @returns
 */
export const updateAdSettings = async (data) => {
  let currentUser = JSON.parse(localStorage.getItem('glanceuser'));
  let payload = {
    ...data,
    key: data.key,
    value: data.value,
  }
  let response = await API.post('/settings/update_settings', payload, {
    headers: {
      user_id: currentUser['_id'],
      token: currentUser['token'],
    }
  });
  console.log('response', response)
  if (response.data.code === 200) {
    return response.data;

  } else {
    toast.error(response?.data?.data || response?.data?.message || "Something went wrong");
    return false;
  }
};

/**
 * Fuction for Delete Ads
 * @param {*} data
 * @returns
 */
export const deleteAd = async (data) => {
  let currentUser = JSON.parse(localStorage.getItem('glanceuser'));
  let payload = {
    ...data,
    ad_id: data._id,
  }
  let response = await API.post('/ad/delete_ad', payload, {
    headers: {
      user_id: currentUser['_id'],
      token: currentUser['token'],
    }
  });
  console.log('response', response)
  if (response.data.code === 200) {
    return response.data;

  } else {
    toast.error(response?.data?.data || response?.data?.message || "Something went wrong");
    return false;
  }
};


/**
 * Fuction for Free Subscription User Listing
 * @param {*} data
 * @returns
 */
export const freeSubscriptionListing = async (data) => {
  let currentUser = JSON.parse(localStorage.getItem('glanceuser'));
  let payload = {
    ...data,
  }
  let response = await API.post('/subscription/free_subscribed_user_list', payload, {
    headers: {
      user_id: currentUser['_id'],
      token: currentUser['token'],
    }
  });
  console.log('response', response)
  if (response.data.code === 200) {
    return response.data;

  } else {
    toast.error(response?.data?.data || response?.data?.msg || "Something went wrong");
    return false;
  }
};



/**
 * Fuction for Deactivate Free Subscription to Users
 * @param {*} data
 * @returns
 */
export const deactivateFreeSubscription = async (data) => {
  let currentUser = JSON.parse(localStorage.getItem('glanceuser'));
  let payload = {
    ...data,
  }
  let response = await API.post('/subscription/deactivate_user_subcription', payload, {
    headers: {
      user_id: currentUser['_id'],
      token: currentUser['token'],
    }
  });
  console.log('response', response)
  if (response.data.code === 200) {
    return response.data;

  } else {
    toast.error(response?.data?.data || response?.data?.msg || "Something went wrong");
    return false;
  }
};


/**
 * Fuction for Add Free Subscription to Users
 * @param {*} data
 * @returns
 */
export const addFreeSubscription = async (data) => {
  let currentUser = JSON.parse(localStorage.getItem('glanceuser'));
  let payload = {
    ...data,
  }
  let response = await API.post('/subscription/add_free_subcription', payload, {
    headers: {
      user_id: currentUser['_id'],
      token: currentUser['token'],
    }
  });
  console.log('response', response)
  if (response.data.code === 200) {
    return response.data;

  } else {
    toast.error(response?.data?.data || response?.data?.msg || "Something went wrong");
    return false;
  }
};
